<style lang="scss">
	@function vw($width) {
		@return ($width * 100vw / 1920);
	}

	@function vh($height) {
		@return ($height * 100vh / 1080);
	}

	.container {
		width: 100%;
		height: 100%;
	}
</style>
<template>
	<div id="container" class="container"></div>
</template>
<script>
    var that;
    export default {
        props:{
          data:{
              type:Object,
	          default:undefined
          }
        },
        mounted() {
            that = this;
            this.init();
        },
        data() {
            return {}
        },
        methods: {
            init() {
                var chartDom = document.getElementById('container');
                var myChart = this.$echarts.init(chartDom);

                var getsjjg = [ "女","男"];
                var getsjjgrs = [this.data.girl_num, this.data.boy_num];

                var syjgdata = [];
                for (var i = 0; i < getsjjg.length; i++) {
                    syjgdata.push({
                        name: getsjjg[i],
                        value: getsjjgrs[i],
                    });
                }
                var syjgdata2 = [];
                for (var i = 0; i < getsjjg.length; i++) {
                    syjgdata2.push({
                        name: getsjjg[i],
                        value: 10,
                    });
                }
                //职称结构图表
                var colorList = ["#2B9AF0", "#29E2FD"];
                var option = {
                    backgroundColor: "transparent",
                    series: [{
                        startAngle:240,
                        itemStyle: {
                            normal: {
                                color: function (params) {
                                    return colorList[params.dataIndex];
                                },
                                borderColor: 'rgba(62,109,255,0.1)',
                                borderWidth: 1,
                                shadowBlur: 6,
                                shadowColor: 'rgba(241,241,241,.4)'
                            },
                        },
                        type: "pie",
                        radius: ["50%", "60%"],
                        center: ["50%", "50%"],
                        labelLine: {
                            normal: {
                                length:4,
                                lineStyle: {
                                    color: "#4BCDF2",
                                },
                            },
                        },
                        label: {
                            normal: {
                                formatter: '{b}\n{c}人',
                                padding: [5, 5],
                                fontSize: 15,
	                            fontWeight:600,
                                color: '#c9e8ff',
                                lineHeight: 20
                            },
                        },
                        data: syjgdata,
                    }],
                };
                option && myChart.setOption(option);

                window.addEventListener('resize', function () {
                    myChart.resize();
                })
            }
        }
    }
</script>
