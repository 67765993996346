"use strict";
import axios from "axios";
import path from "./url";

// 统一前缀路径
let base  = "https://ceme.startsea.cn/api";

// let base = "/xboot";

// 超时设定
axios.defaults.timeout = 15000;

axios.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        // Do something with response error
        return Promise.reject(error);
    }
);

export const getRequest = (url, params) => {
    return axios({
        method: 'get',
        url: `${base}${url}`,
        params: params || {},
    });
};

export const postRequest = (url, params) => {
    return axios({
        method: 'post',
        url: `${base}${url}`,
        params: params || {},
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
};

export const postRequestBody = (url, params) => {
    return axios({
        method: 'post',
        url: `${base}${url}`,
        data: params
    });
};