import {getRequest, postRequest,postRequestBody} from "./axios";

// 首页数据
export const index = () => {
    return postRequest("/index/index");
}

// 首页地图
export const mapPos = () => {
    return postRequest("/index/cemetery_list");
}

// 根据名称获取区域列表 cemetery_id
export const areaList = (params) => {
    return postRequestBody("/index/area_list",params);
}

// 墓位列表 cemetery_id cemeteryarea_id
export const ceme = (params) => {
    return postRequestBody("/index/ceme",params);
}