import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

// 全局引入echarts
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;

// 像素转换工具
import styleUtil from "@/util/styleUtil";
Vue.prototype.$styleUtil = styleUtil;

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
