<style lang="scss">
	@function vw($width) {
		@return ($width / 1920) * 100vw;
	}

	@function vh($height) {
		@return ($height / 1080) * 100vh;
	}

	.home {
		position: relative;
		width: vw(1920);
		height: vh(1080);
		background-image: url(../assets/bg.webp);
		background-size: 100% 100%;
		background-repeat: no-repeat;

		.head > .img {
			width: 100%;
		}

		.home-wrap {
			padding: 0 vw(134) 0 vw(110);

			.back {
				position: absolute;
				top: vh(68);
				left: vw(115);
				display: flex;
				align-items: center;
				justify-content: center;
				width: vw(137);
				height: vh(38);
				background-image: url("../assets/back_bg.png");
				background-size: 100% 100%;
				background-repeat: no-repeat;
				transition: all .3s;

				&:hover {
					transform: scale(1.06);
				}

				img {
					width: vw(19);
					height: vh(18);
					margin-right: vw(10);
				}

				span {
					font-size: vh(18);
					color: #fff;
					font-family: SourceHanSansCN-Regular, SourceHanSansCN;
				}
			}

			.count {
				margin: vh(30) 0 vh(60) 0;

				.count-title {
					position: relative;
					width: vw(382);
					height: vh(49);
					margin: 0 auto;
					background-image: url("../assets/title_bg.png");
					background-size: 100% 100%;
					background-repeat: no-repeat;

					.count-title-wrap {
						position: absolute;
						top: vh(-24);
						left: 50%;
						transform: translateX(-50%);
						height: vh(27);
						font-size: vh(28);
						font-family: SourceHanSansCN-Bold, SourceHanSansCN;
						font-weight: bold;
						color: #FFFFFF;
						text-align: center;
						width: 100%;
					}
				}

				.count-con {
					display: flex;
					justify-content: center;

					.count-con-item {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;

						&:not(:nth-last-child(1)) {
							margin-right: vw(83);
						}

						.count-con-item-txt {
							width: vw(210);
							height: vh(43);
							line-height: vh(43);
							text-align: center;
							background-image: url("../assets/txt_bg.png");
							background-size: 100% 100%;
							background-repeat: no-repeat;
							font-size: vh(18);
							font-family: SourceHanSansCN-Bold, SourceHanSansCN;
							font-weight: bold;
							color: #FFFFFF;
							margin-bottom: vh(13);
						}

						.count-con-item-num {
							position: relative;
							font-size: vh(30);
							font-family: SourceHanSansCN-Bold, SourceHanSansCN;
							font-weight: bold;
							color: #FFFFFF;

							&::after {
								content: '';
								display: inline-block;
								position: absolute;
								left: 50%;
								transform: translateX(-50%);
								bottom: vh(-4);
								width: vw(149);
								height: vh(2);
								background-image: linear-gradient(90deg, #0A2F63 5%, #4CA3D2 60%, #0B2D5F 100%);
							}
						}
					}
				}
			}

			.sel {
				.label {
					width: vw(40);
					height: vh(17);
					font-size: vh(18);
					font-family: SourceHanSansCN-Regular, SourceHanSansCN;
					font-weight: 400;
					color: #2EA4FF;
					margin-right: vw(25);
				}

				select {
					width: vw(100);
					height: vh(24);
					background: transparent;
					border: 1px solid #1D80DA;
					color: #4CA3D2;
					border-radius: 4px;

					&:focus-visible {
						outline: none;
					}
				}
			}

			/*分隔线*/
			.line {
				width: vw(1690);
				height: vh(3);
				margin-bottom: vh(50);

				img {
					width: 100%;
				}
			}

			/*墓位*/
			.tomb {
				overflow-x: auto;
				cursor: pointer;

				/*滚动条*/
				&:hover::-webkit-scrollbar {
					display: block;
				}

				&::-webkit-scrollbar {
					height: vh(12);
					display: none;
				}

				&::-webkit-scrollbar-thumb {
					border-radius: 6px;
					background: rgba(255, 255, 255, .8);
				}

				&::-webkit-scrollbar-track {
					height: vh(4);
					background: rgba(8, 77, 139, .5);
					border-radius: 6px;
				}

				.tomb-wrap {

					.head {
						display: flex;
						margin-bottom: vh(10);
						padding-left: vw(22);

						.head-item {
							width: vw(94);
							height: vh(20);
							line-height: vh(20);
							font-size: vh(16);
							font-family: SourceHanSansCN-Regular, SourceHanSansCN;
							color: #FFFFFF;
							text-align: center;
							margin-right: vw(10);
							flex-shrink: 0;
						}
					}

					.row-wrap {
						display: flex;
						height: vh(500);
						overflow: auto;

						&::-webkit-scrollbar {
							display: none;
							width: vw(8);
							background: rgba(255, 255, 255, .2);
							border-radius: vw(4);
						}

						.row-title {

							.row-title-item {
								width: vw(22);
								height: vh(127);
								font-size: vh(16);
								font-family: SourceHanSansCN-Regular, SourceHanSansCN;
								font-weight: 400;
								color: #FFFFFF;
								margin-right: vw(0);
								text-align: center;
								padding-top: vh(26);
								box-sizing: border-box;
							}
						}

						.row {
							display: flex;
							margin-bottom: vh(10);

							.col {
								margin-right: vw(10);

								.tomb-item-l {
									display: flex;
									justify-content: center;
									align-items: center;
									width: vw(15);
									font-size: vh(16);
									font-family: SourceHanSansCN-Regular, SourceHanSansCN;
									font-weight: 400;
									color: #FFFFFF;
									text-align: center;
									margin-right: vw(8);
								}

								.tomb-item-r-con {
									display: flex;
									flex-direction: column;
									justify-content: center;
									align-items: center;
									width: vw(94);
									height: vh(117);
									background-image: url("../assets/yellow_bg_shadow.png");
									background-size: 100% 100%;
									background-repeat: no-repeat;

									.txt {
										font-size: vh(16);
										font-family: SourceHanSansCN-Regular, SourceHanSansCN;
										font-weight: 400;
										color: #FFFFFF;
										margin-bottom: vh(16);
									}

									.btn {
										width: vw(59);
										height: vh(24);
										line-height: vh(24);
										text-align: center;
										font-size: vh(16);
										font-weight: bold;
										background: #FBC243;
									}
								}

								.activeBg {
									background-image: url("../assets/blue_bg_shadow.png");

									.btn {
										background: #2EA4FF;
									}
								}

								.activeNo {
									background-image: url("../assets/gray.png");

									.btn {
										background: #727272;
									}
								}
							}
						}
					}
				}
			}
		}

		.bottom > img {
			position: absolute;
			bottom: 0;
			width: vw(1920);
			height: vh(30);
		}
	}
</style>
<template>
	<div class="home" v-loading="loading">
		<!--顶部图片-->
		<div class="head">
			<img src="../assets/head.png" alt="" class="img">
		</div>

		<div class="home-wrap">
			<!--返回按钮-->
			<div class="back" @click="handleToHome">
				<img src="../assets/back_arrow.png" alt=""> <span>返回</span>
			</div>

			<!--中间统计数量-->
			<div class="count">
				<div class="count-title">
					<div class="count-title-wrap">
						{{name}}
					</div>
				</div>
				<div class="count-con">
					<div class="count-con-item">
						<div class="count-con-item-txt">墓位总数</div>
						<div class="count-con-item-num">{{baseData && baseData.all_count || 0}}</div>
					</div>
					<div class="count-con-item">
						<div class="count-con-item-txt">空闲墓位数</div>
						<div class="count-con-item-num">{{baseData && baseData.k_count || 0}}</div>
					</div>
					<div class="count-con-item">
						<div class="count-con-item-txt">占用墓位数</div>
						<div class="count-con-item-num">{{baseData && baseData.z_count || 0}}</div>
					</div>
					<div class="count-con-item">
						<div class="count-con-item-txt">墓区数量</div>
						<div class="count-con-item-num">{{baseData && baseData.area_count || 0}}</div>
					</div>
				</div>
			</div>

			<!--下拉选项-->
			<div class="sel">
				<span class="label">墓区:</span> <select v-on:change="selectChange" name="select" id="select"
				                                       v-model="searchParams.cemeteryarea_id">
				<option v-for="(item,indx) in data" :key="indx" :value="item.id">{{item.name}}</option>
			</select>
			</div>

			<!--分割线-->
			<div class="line">
				<img src="../assets/line.png" alt="">
			</div>

			<!--墓位-->
			<div class="tomb" v-if="baseData">
				<div class="tomb-wrap" :style="{width:(cols * (106 / 1920 * 100)) + 'vw'}">

					<!--行表头-->
					<div class="head">
						<div class="head-item" v-for="item in cols" :key="item">第{{item}}列</div>
					</div>

					<div class="row-wrap">

						<!--列表头-->
						<div class="row-title">
							<div class="row-title-item" v-for="(item,indx) in baseData.list.length"
							     :key="item"> {{('第'+(indx + 1) + '排')}}
							</div>
						</div>
						<div class="wrap" :style="{width:(cols * 106) + 'px'}">
							<div class="row" v-for="(row ,indx) in baseData.list" :key="indx">
								<div class="col" v-for="(col,i) in row.lines" :key="i">
									<div class="tomb-item-r-title"></div>
									<div class="tomb-item-r-con" :class="parseStatus(col)">
										<div class="txt">{{col.status==3?' ': col.number }}</div>
										<div class="btn" v-if="col.status == 1">占用</div>
										<div class="btn" v-if="col.status == 2">空闲</div>
										<div class="btn" v-if="col.status == 3 || col.status == ''">暂无</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!--底部边框-->
		<div class="bottom">
			<img src="../assets/bottom.png" alt="">
		</div>
	</div>
</template>

<script>
    import {
        areaList,
        ceme
    } from "../plugins/api.js";

    export default {
        data() {
            return {
                data: [],
                searchParams: {
                    cemetery_id: '',
                    cemeteryarea_id: ''
                },
                baseData: undefined,
                loading: false,
                name: '',
                cols: 0
            }
        },
        watch: {
            'searchParams.cemeteryarea_id'(newVal, oldVal) {
                this.getAllTomb();
            },
        },
        mounted() {
            this.searchParams.cemetery_id = this.$route.params.cemetery_id;
            this.name = this.$route.params.name;

            this.getData();
        },
        methods: {
            parseStatus(item) {
                if (item.status == 2) {
                    return 'activeBg'
                } else if (item.status == 3 || item.status == '') {
                    return 'activeNo'
                } else {
                    return '';
                }
            },

            selectChange(e) {
                this.searchParams.cemeteryarea_id = e.target.value;
                this.loading = true;
                this.getAllTomb();
            },

            handleToHome() {
                window.history.back(-1);
            },

            getData() {
                this.loading = true;
                areaList(this.searchParams).then(res => {
                    this.loading = false;
                    if (res.data.code == 1 && res.data.data.length > 0) {
                        this.data = res.data.data;

                        // 初始化
                        this.searchParams.cemeteryarea_id = this.data[0].id;
                    }
                })
            },
            // 获取所有墓位
            getAllTomb() {
                this.loading = true;
                ceme(this.searchParams).then(res => {
                    this.baseData = res.data.data || [];
                    this.loading = false;

                    // 获取最大列数
                    if (this.baseData && this.baseData.list.length > 0) {
                        this.baseData.list.map(i => {
                            if (this.cols < i.lines.length) {
                                this.cols = i.lines.length;
                            }
                        })
                    }
                })
            }
        }
    }
</script>