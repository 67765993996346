<style lang="scss">
	@function vw($width) {
		@return ($width / 1920) * 100vw;
	}

	@function vh($height) {
		@return ($height / 1080) * 100vh;
	}

	.home1 {
		position: relative;
		width: vw(1920);
		height: vh(1080);
		background-image: url(../assets/home_bg.jpg);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		overflow: hidden;

		.head > .img {
			width: 100%;
		}

		.tl {
			top: -2px;
			left: -1px;
		}

		.bl {
			bottom: -2px;
			left: -1px;
		}

		.tr {
			top: -2px;
			right: -1px;
		}

		.br {
			bottom: -2px;
			right: -1px;
		}

		.mode {
			position: absolute;
			background: #2D8DFC;
			width: 14px;
			height: 2px;
		}

		.con {
			display: flex;
			padding: 0 vw(16);

			.con-l {

				.death {
					position: relative;
					border: 2px solid #00357E;
					padding: vh(10) vw(10);

					&:not(:nth-last-child(1)) {
						margin-bottom: vh(14);
					}

					.death-img {
						width: vw(410);
						height: vh(50);
					}

					.cons {
						display: flex;
						justify-content: space-around;
						align-items: center;

						.con-item {
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;

							.con-item-number {
								width: vw(69);
								height: vh(58);
								line-height: vh(58);
								background-image: url("../assets/box.png");
								background-size: 100% 100%;
								background-repeat: no-repeat;
								overflow: hidden;
								color: #fff;
								text-align: center;
								font-size: vh(18);
								font-weight: bold;
								margin-bottom: vh(10);
							}

							.con-item-txt {
								color: #8BC8FF;
								font-size: vh(16);
								font-family: SourceHanSansCN-Regular, SourceHanSansCN;
								font-weight: 400;
							}
						}
					}
				}

				/*逝者人数*/
				.szrs {
					display: flex;
					width: vw(410);
					height: vh(285);
					background-image: url(../assets/szrs.png);
					background-size: 100% 100%;
					background-repeat: no-repeat;
					padding-top: vh(71);
					padding-left: vw(22);
					box-sizing: border-box;
					margin-bottom: vh(28);

					.szrs-l {
						display: flex;
						flex-direction: column;
						align-items: center;

						img {
							width: vw(110);
							height: vh(116);
							margin-bottom: vh(20);
						}

						.count {
							width: vw(160);
							font-size: vh(34);
							color: #FFFFFF;
							text-shadow: 1px 1px 20px #0859f6;
							font-weight: bold;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							text-align: center;
							font-family: SourceHanSansCN-Bold, SourceHanSansCN;
						}

						.person-txt {
							font-size: vh(18);
							font-family: SourceHanSansCN-Regular, SourceHanSansCN;
							font-weight: 400;
							color: #8BC8FF;
							margin-left: vw(4);
						}
					}

					.szrs-r {
						position: relative;
						width: vw(240);
						height: vh(180);

						.part-one {
							display: flex;
							justify-content: center;
							align-items: center;
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);
							width: vw(82);
							height: vw(82);
							background-image: url("../assets/circle_bg.png");
							background-size: 100% 100%;
							background-repeat: no-repeat;

							& > img {
								width: vw(26);
								height: vh(40);
								margin-right: 0;

								&:nth-child(1) {
									margin-right: vw(8)
								}
							}
						}

						.part-two {
							width: 100%;
							height: 100%;
						}
					}
				}
			}

			.con-l-person-chart {
				position: relative;
				display: flex;
				width: vw(360);

				.person_img {
					width: vw(95);
					height: vh(99);
				}

				img {
					margin-right: vw(40);
				}
			}

			/*滚动列表*/
			.con-l-person-table {
				width: vw(410);

				.th {
					width: 100%;
					height: vh(40);
					padding-left: vw(16);
					box-sizing: border-box;
					line-height: vh(40);
					background: #081535;
					display: flex;

					.th-item {
						width: vw(82);
						color: #8BC8FF;
						font-size: vh(16);
						text-align: center;
					}
				}

				.seamless-warp {
					height: vh(100);
					overflow: hidden;

					.tb {
						height: vh(40);
						line-height: vh(40);
						display: flex;
						padding-left: vw(16);
						box-sizing: border-box;

						.tb-item {
							width: vw(82);
							color: #8BC8FF;
							font-size: vh(16);
							text-align: center;
						}
					}
				}

				.day {
					width: vw(112) !important;
				}
			}

			.con-m {
				display: flex;
				justify-content: center;
				align-items: center;
				width: vw(1048);
				height: vh(855);

				.con-m-wrap {
					width: vw(1040);
					height: vh(855);
					background-image: url(../assets/map.webp);
					background-size: 100% 100%;
					background-position: center;
					background-repeat: no-repeat;

					.myEcharts {
						width: 100%;
						height: 100%;

						canvas {
							transform: scale(1.2);
						}
					}
				}
			}

			.con-r {
				/*业务数据统计*/
				.con-r-business {
					width: vw(410);
					height: vh(160);
					background-image: url(../assets/ywsj.png);
					background-size: 100% 100%;
					background-repeat: no-repeat;
					box-sizing: border-box;
					padding-top: vh(56);
					padding-left: vw(14);
					margin-bottom: vh(28);

					.con-r-business-title {
						margin-bottom: vh(30);
						height: 18px;
						font-size: vh(16);
						font-family: SourceHanSansCN-Regular, SourceHanSansCN;
						font-weight: 400;
						color: #2EA4FF;

						&::before {
							content: '';
							display: inline-block;
							margin-right: vw(4);
							width: 0;
							height: 0;
							font-size: 0;
							border-style: solid;
							border-color: transparent transparent transparent #fff;
							border-width: 5px 0 5px 5px;
						}
					}

					.con-r-business-chart {
						display: flex;

						.business-item {
							display: flex;
							align-items: center;

							&:nth-child(1) {
								margin-right: vw(40)
							}

							.business-item-img {
								width: vw(64);
								height: vh(80);
								margin-right: vw(22);

								& > img {
									width: 100%;
									height: 100%;
								}
							}

							.business-item-desc {
								.business-item-desc-txt {
									font-size: vh(16);
									color: #8BC8FF;
									margin-bottom: vh(12);
								}

								.business-item-desc-num {
									font-size: vh(24);
									color: #DCE3EB;
									text-shadow: 1px 1px 15px #0857EE;
									font-weight: bold;

									span {
										font-size: vh(12);
										color: #5C7791;
										margin-left: vw(4);
									}
								}
							}
						}
					}
				}

				/*火化情况*/
				.con-r-fire {
					width: vw(410);
					height: vh(216);
					background-image: url(../assets/hhqk.png);
					background-size: 100% 100%;
					background-repeat: no-repeat;
					box-sizing: border-box;
					padding-top: vh(50);
					padding-left: vw(14);
					margin-bottom: vh(28);

					.con-r-fire-chart {
						height: vh(166);
					}
				}

				/*遗体来源情况*/
				.ytly {
					width: vw(410);
					height: vh(216);
					background-image: url(../assets/ytly.png);
					background-size: 100% 100%;
					background-repeat: no-repeat;
					box-sizing: border-box;
					padding-top: vh(50);
					padding-left: vw(14);
					margin-bottom: vh(28);

					.con-r-fire-chart {
						height: vh(160);
					}
				}

				/*冷藏柜状态*/
				.lcgzt {
					width: vw(410);
					height: vh(269);
					background-image: url(../assets/lcgzt.png);
					background-size: 100% 100%;
					background-repeat: no-repeat;
					box-sizing: border-box;
					padding-top: vh(50);
					padding-left: vw(17);
					overflow: hidden;

					.con-r-table-con {
						.th {
							height: vh(40);
							padding-left: vw(16);
							box-sizing: border-box;
							line-height: vh(40);
							background: #081535;
							display: flex;
						}

						.tb {
							height: vh(40);
							line-height: vh(40);
							display: flex;
							padding-left: vw(16);
							box-sizing: border-box;
						}

						.status-warp {
							height: vh(172);
							overflow: hidden;
						}

						.t-item {
							width: vw(237);
							color: #8BC8FF;
							font-size: vh(16);
						}

						.last {
							width: vw(113);
						}
					}
				}
			}
		}

		.bottom > img {
			position: absolute;
			bottom: 0;
			width: vw(1920);
			height: vh(30);
		}
	}
</style>
<template>
	<div class="home1" v-loading="loading">
		<!--顶部图片-->
		<div class="head">
			<img src="../assets/head.png" alt="" class="img">
		</div>

		<!--统计区-->
		<div class="con">

			<!--逝者信息分析-->
			<div class="con-l">

				<!--逝者数据-->
				<div class="death">
					<div class="tl mode"></div>
					<div class="bl mode"></div>
					<div class="tr mode"></div>
					<div class="br mode"></div>
					<img src="../assets/szrs1.png" alt="" class="death-img">
					<div class="cons">
						<div class="con-item">
							<div class="con-item-number">{{(baseData && baseData.member_count) || 0}}</div>
							<div class="con-item-txt">逝者人数</div>
						</div>
						<div class="con-item">
							<div class="con-item-number">{{(baseData && baseData.boy_num) || 0}}</div>
							<div class="con-item-txt">男</div>
						</div>
						<div class="con-item">
							<div class="con-item-number">{{(baseData && baseData.girl_num) || 0}}</div>
							<div class="con-item-txt">女</div>
						</div>
					</div>
				</div>

				<!--冷藏柜数据-->
				<div class="death">
					<div class="tl mode"></div>
					<div class="bl mode"></div>
					<div class="tr mode"></div>
					<div class="br mode"></div>
					<img src="../assets/lcgsj1.png" alt="" class="death-img">
					<div class="cons">
						<div class="con-item">
							<div class="con-item-number">{{baseData && baseData.all_code || 0}}</div>
							<div class="con-item-txt">冷藏柜总数</div>
						</div>
						<div class="con-item">
							<div class="con-item-number">{{baseData && baseData.k_code || 0}}</div>
							<div class="con-item-txt">空闲冷藏柜</div>
						</div>
						<div class="con-item">
							<div class="con-item-number">{{baseData && baseData.z_code || 0}}</div>
							<div class="con-item-txt">占用冷藏柜
							</div>
						</div>
					</div>
				</div>

				<!--告别室数据-->
				<div class="death">
					<div class="tl mode"></div>
					<div class="bl mode"></div>
					<div class="tr mode"></div>
					<div class="br mode"></div>
					<img src="../assets/gbtsj.png" alt="" class="death-img">
					<div class="cons">
						<div class="con-item">
							<div class="con-item-number">{{baseData && baseData.all_leaveroom_count || 0}}</div>
							<div class="con-item-txt">告别厅总数</div>
						</div>
						<div class="con-item">
							<div class="con-item-number">{{baseData && baseData.leaveroom_null || 0}}</div>
							<div class="con-item-txt">空闲告别厅</div>
						</div>
						<div class="con-item">
							<div class="con-item-number">{{baseData && baseData.leaveroom_have || 0}}</div>
							<div class="con-item-txt">占用告别厅</div>
						</div>
					</div>
				</div>

				<!--守灵室数据-->
				<div class="death">
					<div class="tl mode"></div>
					<div class="bl mode"></div>
					<div class="tr mode"></div>
					<div class="br mode"></div>
					<img src="../assets/slssj.png" alt="" class="death-img">
					<div class="cons">
						<div class="con-item">
							<div class="con-item-number">{{baseData && baseData.all_wakeroomcount || 0}}</div>
							<div class="con-item-txt">守灵室总数</div>
						</div>
						<div class="con-item">
							<div class="con-item-number">{{baseData && baseData.wakeroomcount_null || 0}}</div>
							<div class="con-item-txt">空闲守灵室</div>
						</div>
						<div class="con-item">
							<div class="con-item-number">{{baseData && baseData.wakeroomcount_have || 0}}</div>
							<div class="con-item-txt">占用守灵室</div>
						</div>
					</div>
				</div>

				<!--逝者详情-->
				<div class="death">
					<div class="tl mode"></div>
					<div class="bl mode"></div>
					<div class="tr mode"></div>
					<div class="br mode"></div>
					<img src="../assets/szxq1.png" alt="" class="death-img">
					<div class="con-l-person-table">
						<div class="th">
							<div class="th-item">姓名</div>
							<div class="th-item">性别</div>
							<div class="th-item">年龄</div>
							<div class="th-item day">祭日</div>
						</div>
						<vueSeamlessScroll v-if="baseData && baseData.list" :data="baseData.list" class="seamless-warp"
						                   :class-option="defaultOption">
							<div class="tb" v-for="(item,indx) in baseData.list" :key="indx"
							     :style="{'background':indx%2==1?'':'#0B1D31'}">
								<div class="tb-item">{{item.name}}</div>
								<div class="tb-item">{{item.sex}}</div>
								<div class="tb-item">{{item.age}}</div>
								<div class="tb-item day">{{item.die_time}}</div>
							</div>
						</vueSeamlessScroll>
					</div>
				</div>
			</div>

			<!--墓位分区分析-->
			<div class="con-m">
				<div class="con-m-wrap">
					<div class="myEcharts" id="myEcharts"></div>
				</div>
			</div>

			<!--业务数据分析-->
			<div class="con-r">
				<!--业务数据统计-->
				<div class="con-r-business">
					<div class="con-r-business-chart">
						<div class="business-item">
							<div class="business-item-img">
								<img src="../assets/fire.png" alt="">
							</div>
							<div class="business-item-desc">
								<div class="business-item-desc-txt">火化数量</div>
								<div class="business-item-desc-num">
									{{baseData && baseData.fire_count || 0}}<span>个</span>
								</div>
							</div>
						</div>
						<div class="business-item">
							<div class="business-item-img">
								<img src="../assets/trans.png" alt="">
							</div>
							<div class="business-item-desc">
								<div class="business-item-desc-txt">遗体接运量</div>
								<div class="business-item-desc-num">
									{{baseData && baseData.carry_count || 0}}<span>个</span>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!--火化情况-->
				<div class="con-r-fire">
					<div class="con-r-fire-chart" id="myCategory"></div>
				</div>

				<!--接运遗体来源-->
				<div class="ytly">
					<div class="con-r-fire-chart" id="recieveBody"></div>
				</div>

				<!--冷藏柜状态-->
				<div class="lcgzt">
					<div class="con-r-table-con">
						<div class="th">
							<div class="t-item">冷藏柜编号</div>
							<div class="t-item last">当前状态</div>
						</div>
						<vueSeamlessScroll v-if="baseData && baseData.code_list" :data="baseData.code_list"
						                   class="status-warp" :class-option="defaultOption">
							<div class="tb" v-for="(item,indx) in baseData.code_list"
							     :style="{'background':indx%2==1?'':'#0B1D31'}">
								<div class="t-item">{{item.name}}</div>
								<div class="t-item last">{{item.status}}</div>
							</div>
						</vueSeamlessScroll>
					</div>
				</div>
			</div>
		</div>

		<!--底部边框-->
		<div class="bottom">
			<img src="../assets/bottom.png" alt="">
		</div>
	</div>
</template>
<script>
    let that;
    import xiangyuanxian from "../assets/json/xiangyuanxian";//襄垣县地图数据
    import category from "../components/category";//遗体来源
    import fire from "../components/fire.vue";//火化情况
    import test from "./test.vue";//男女比例
    import {index, mapPos} from "../plugins/api.js";//请求接口
    import vueSeamlessScroll from "vue-seamless-scroll";//无限滚动

    export default {
        components: {
            test, category, fire, vueSeamlessScroll
        },
        data() {
            return {
                baseData: undefined,
                defaultOption: {
                    step: 0.2, // 数值越大速度滚动越快
                    limitMoveNum: 4, // 开始无缝滚动的数据量 this.dataList.length
                    hoverStop: false, // 是否开启鼠标悬停stop
                    direction: 1, // 0向下 1向上 2向左 3向右
                    openWatch: true, // 开启数据实时监控刷新dom
                    singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                    singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                    waitTime: 2000, // 单步运动停止的时间(默认
                },
                loading: false
            }
        },
        mounted() {
            that = this;
            this.loading = true;
            // 首页
            index().then(res => {
                this.loading = false;
                this.baseData = res.data.data;

                console.log(res.data.data)

                // 火化情况
                that.init(res.data.data);

                // 接运遗体来源
                that.initBody(res.data.data);
            });

            // 地图点
            mapPos().then(res => {
                this.loading = false;
                that.mapInit(res.data.data);
            })
        },

        methods: {
            // 火化统计图
            init(data) {
                var chartDom = document.getElementById('myCategory');
                var myChart = this.$echarts.init(chartDom);
                var option = {
                    xAxis: {
                        type: 'category',
                        data: ['开始火化', '火化完成', '骨灰捡取完成'],
                        axisLabel: {
                            show: true,
                            fontSize: 12,
                            color: '#8BC8FF',
                            textStyle: {
                                color: '#77ADDF'   //这里用参数代替了
                            }
                        },
                    },
                    yAxis: {
                        type: 'value',
                        splitLine: {
                            lineStyle: {
                                type: 'dashed',
                                color: '#222F43'
                            }
                        },
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: '#77ADDF'   //这里用参数代替了
                            }
                        },
                    },
                    grid: {
                        top: '15%',
                        right: '8%',
                        bottom: '19%',
                        left: '10%'
                    },
                    series: [{
                        data: [data.start_fire, data.over_fire, data.pick_fire],
                        type: 'bar',
                        barWidth: 30, // 柱子宽
                        showBackground: true,
                        itemStyle: {
                            color: new that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 1, color: '#B5D7FF'
                            }, {offset: 0, color: '#4C92F1'}])
                        },
                    }]
                };
                option && myChart.setOption(option);
                this.screenResize(myChart);
            },

            // 接运遗体来源统计
            initBody(data) {
                var chartDom = document.getElementById('recieveBody');
                var myChart = this.$echarts.init(chartDom);

                var charts = {
                    cityList: [],
                    cityData: [],
                };

                for (let i = 0; i < data.source_list.length; i++) {
                    if (i < 4) {
                        charts.cityList.unshift(data.source_list[i].source);
                        charts.cityData.unshift(data.source_list[i].number);
                    }
                }
                var color = ["rgba(15,225,248"];

                let lineY = [];
                for (var i = 0; i < charts.cityList.length; i++) {
                    var x = i;
                    if (x > color.length - 1) {
                        x = color.length - 1;
                    }
                    var data = {
                        name: charts.cityList[i],
                        color: color[x] + ")",
                        value: charts.cityData[i],
                        itemStyle: {
                            normal: {
                                show: false,
                                color: new that.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                    offset: 0,
                                    color: color[x] + ", 0.3)",
                                }, {
                                    offset: 1,
                                    color: color[x] + ", 1)",
                                }], false),
                                barBorderRadius: 10,
                            },
                        },
                    };
                    lineY.push(data);
                }

                var option = {
                    backgroundColor: "transparent",
                    grid: {
                        borderWidth: 0,
                        top: "10%",
                        left: "4%",
                        right: "15%",
                        bottom: "3%",
                    },
                    color: color,
                    yAxis: [{
                        type: "category",
                        inverse: true,
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            show: false,
                        },
                        axisLabel: {
                            show: false,
                            inside: false,
                        },
                    }],
                    xAxis: {
                        type: "value",
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                        axisLabel: {
                            show: false,
                        },
                    },
                    series: [{
                        name: "Direct",
                        type: "bar",
                        barWidth: "10px",
                        stack: 'total',
                        data: lineY,
                        label: {
                            show: true,
                            normal: {
                                color: "#8BC8FF",
                                show: true,
                                position: ["0", "-16px"],
                                textStyle: {
                                    fontSize: 14,
                                },
                                formatter: function (a, b) {
                                    return a.name + '（' + a.value + '）';
                                },
                            },
                        },
                    }],
                    animationEasing: "cubicOut",
                };
                option && myChart.setOption(option);
                this.screenResize(myChart);
            },

            // 中间地图
            mapInit(data) {
                this.$echarts.registerMap('xyx', {geoJSON: xiangyuanxian});
                let Chart = this.$echarts.init(document.getElementById("myEcharts"));

                var icon = require('../assets/sjxb.png');
                var icon_selected = require('../assets/sjxh.png');

                // 地图数据
                var series = [];
                for (let i = 0; i < data.length; i++) {
                    series.push({
                        type: "scatter",
                        coordinateSystem: "geo",
                        symbol: "image://" + icon,
                        symbolSize: [22, 20],//30 36
                        label: {
                            normal: {
                                show: true,
                                offset: [0, -20],
                                fontSize: 13,
                                backgroundColor: "rgba(21,66,119,.5)",
                                borderColor: "#1481D9",
                                borderWidth: 1,
                                padding: 6,
                                formatter(item) {
                                    return item.name;
                                },
                                color: '#fff'
                            },
                            emphasis: {
                                show: true,//鼠标点上去是否消失
                            },
                        },
                        data: [{
                            id: data[i].id,
                            name: data[i].name,
                            value: [data[i].long, data[i].lat],
                        }]
                    });
                }

                // 颜色或文字的配置
                let option = {
                    geo: [{
                        type: "map",
                        map: "xyx",
                        label: {
                            show: true,
                            normal: {
                                show: true, // 默认地图文字字号和字体颜色
                                fontSize: 14,
                                color: "#fff",
                            },
                            emphasis: {
                                show: true,
                                fontSize: 14, // 选中地图文字字号和字体颜色
                                color: "#fff",
                                backgroundColor: 'transparent'
                            },
                        },
                        itemStyle: {
                            normal: {
                                opacity: 0, //图形透明度
                                areaColor: '#001133',
                                borderWidth: 0,//设置外层边框
                                borderColor: '#6AC2F1',
                                shadowOffsetX: 0,
                                shadowOffsetY: 0,
                                shadowColor: 'rgba(187,255,255, 0)',
                            },
                            emphasis: {
                                areaColor: "#040c3c", // 高亮时候地图显示的颜色
                                borderWidth: 0, // 高亮时的边框宽度
                            }
                        }
                    }],
                    series: series,
                };
                Chart.setOption(option);
                // 自适应
                this.screenResize(Chart);

                // label点击事件
                Chart.on("click", function (e) {
                    that.$router.push({name: 'area', params: {cemetery_id: e.data.id, name: e.data.name}});
                });

                // label鼠标上移事件
                Chart.on('mouseover', function (e) {
                    // 选中的
                    if (e && e.data && e.data.id) {
                        // 把所有的置为未选中
                        option.series.map(i => {
                            i.symbol = "image://" + icon;
                            i.label.normal.backgroundColor = 'rgba(21,66,119,.5)';
                            i.label.normal.borderColor = '#1481D9';
                        });

                        // 标记选中
                        let t = option.series.find(i => i.data[0].id == e.data.id);
                        t.symbol = "image://" + icon_selected;
                        t.label.normal.backgroundColor = '#D99C34';
                        t.label.normal.borderColor = 'transparent';
                    }
                    Chart.setOption(option);
                })
            },

            // ehcart自适应
            screenResize(myChart) {
                //（当浏览器窗口缩小的时候）。超过了div的界限（红色边框）
                window.addEventListener('resize', function () {
                    myChart.resize()
                });
            }
        }
    }
</script>
