<template>
	<div class="lines">
		<qiun-vue-ucharts type="column" :opts="opts" :chartData="chartData"/>
	</div>
</template>

<script>
    import qiunVueUcharts from '@qiun/vue-ucharts'

    export default {
        components: {
            qiunVueUcharts
        },
        data() {
            return {
                chartData: {},
                opts: {
                    color: ["#9A60B4","#FC8452","#73C0DE","#EE6666","#1890FF","#ea7ccc","#91CB74","#FAC858","#3CA272",],
                    padding: [15, 15, 0, 5],
                    enableScroll: false,
                    legend: {
                        show: false
                    },
                    xAxis: {
                        disableGrid: true,
                    },
                    yAxis: {
                        data: [
                            {
                                min: 0
                            }
                        ]
                    },
                    extra: {
                        column: {
                            type: "group",
                            width: 20,
                            activeBgColor: "#000000",
                            activeBgOpacity: 0.08,
                            linearType: "custom",
                            seriesGap: 5,
                            linearOpacity: 0.5,
                            customColor: [
                                "#FA7D8D",
                                "#EB88E2"
                            ]
                        }
                    }
                }
            };
        },
        mounted() {
            this.getServerData();
        },
        methods: {
            getServerData() {
                //模拟从服务器获取数据时的延时
                setTimeout(() => {
                    //模拟服务器返回数据，如果数据格式和标准格式不同，需自行按下面的格式拼接
                    let res = {
                        categories: ["2018", "2019", "2020"],
                        series: [{
                            name: "完成量",
                            data: [18, 27, 21]
                        }]
                    };
                    this.chartData = JSON.parse(JSON.stringify(res));
                }, 500);
            }
        }
    };
</script>

<style lang="scss">

	@function vw($width) {
		@return ($width * 1vw / 19.2);
	}

	@function vh($height) {
		@return ($height * 1vh / 10.8);
	}

	.lines {
		width: vw(377);
		height: vh(150);

		.canvas {
			width: 100%;
			height: 100%;
		}
	}
</style>
