//定义设计稿的宽高
let designWidth = 1920;
let designHeight = 1080;

window.addEventListener('resize',function(){
    designWidth = window.innerWidth||document.documentElement.clientWidth||document.body.clientWidth;
    designHeight = window.innerHeight||document.documentElement.clientHeight||document.body.clientHeight;
});

export function px2rate(px){

}


let styleUtil = {

    // px转vw
    px2vw: function (_px) {

        return _px * 100.0 / designWidth ;
    },
    // px转vh
    px2vh: function (_px) {
        return _px * 100.0 / designHeight;
    },


};

