<template>
	<div class="lines">
		<qiun-vue-ucharts
			type="bar"
			:opts="opts"
			:chartData="chartData"
		/>
	</div>
</template>

<script>
    import qiunVueUcharts from '@qiun/vue-ucharts'
    export default {
        components:{
            qiunVueUcharts
        },
        data() {
            return {
                chartData: {},
                opts: {
                    color: ["#2D9BF0"],
                    padding: [15,30,0,5],
                    enableScroll: false,
                    legend: {
                        show:false
                    },
                    xAxis: {
                        boundaryGap: "justify",
                        disableGrid: false,
                        min: 0,
                        axisLine: false,
                        max: 30
                    },
                    yAxis: {},
                    extra: {
                        bar: {
                            type: "group",
                            width: 10,
                            meterBorde: 2,
                            meterFillColor: "#FFFFFF",
                            activeBgColor: "#000000",
                            activeBgOpacity: 0.08,
                            linearType: "custom",
                            barBorderCircle: true,
                            seriesGap: 1,
                            categoryGap: 1
                        }
                    }
                }
            };
        },
        mounted() {
            this.getServerData();
        },
        methods: {
            getServerData() {
                //模拟从服务器获取数据时的延时
                setTimeout(() => {
                    //模拟服务器返回数据，如果数据格式和标准格式不同，需自行按下面的格式拼接
                    let res = {
                        categories: ["2018","2019","2020","2021","2022","2023"],
                        series: [ {
                                name: "完成量",
                                data: [18,27,21,24,6,28]
                            }  ]
                    };
                    this.chartData = JSON.parse(JSON.stringify(res));
                }, 500);
            },


            // drawCharts(id, data) {
            //     // 获取canvas对象
            //     const canvas = document.getElementById('AhNrAHDcbSyIGCOtVTDKPhhFDZXRwZee');
            //     canvas.width = canvas.offsetWidth;
            //     canvas.height = canvas.offsetHeight;
            //     // 渲染方式
            //     let ctx = canvas.getContext("2d");
			//
            //     uChartsInstances[id] = new uCharts({
            //         type: "area",
            //         context: ctx,
            //         width: canvas.width,
            //         height: canvas.height,
            //         categories: data.categories,
            //         series: data.series,
            //         timing: "easeOut",
            //         duration: 1000,
            //         rotate: false,
            //         rotateLock: false,
            //         background: "#FFFFFF",
            //         // 折线颜色
            //         color: ['#1890FF', "#19BE6B", '#FFFF00'],
            //         padding: [15, 15, 0, 15],
            //         fontSize: 16,
            //         fontColor: "#FFFFFF",
            //         dataLabel: true,
            //         dataPointShape: true,
            //         dataPointShapeType: "solid",
            //         touchMoveLimit: 60,
            //         enableScroll: false,
            //         enableMarkLine: false,
            //         legend: {
            //             show: true,
            //             position: "bottom",
            //             float: "center",
            //             padding: 5,
            //             margin: 5,
            //             backgroundColor: "rgba(0,0,0,0)",
            //             borderColor: "rgba(0,0,0,0)",
            //             borderWidth: 0,
            //             fontSize: 16,
            //             fontColor: "#FFFFFF",
            //             lineHeight: 11,
            //             hiddenColor: "#CECECE",
            //             itemGap: 10
            //         },
            //         xAxis: {
            //             disableGrid: true,
            //             disabled: false,
            //             axisLine: true,
            //             axisLineColor: "#CCCCCC",
            //             calibration: false,
            //             fontColor: "#FFFFFF",
            //             fontSize: 13,
            //             rotateLabel: false,
            //             rotateAngle: 45,
            //             itemCount: 5,
            //             boundaryGap: "center",
            //             splitNumber: 5,
            //             gridColor: "#CCCCCC",
            //             gridType: "solid",
            //             dashLength: 4,
            //             gridEval: 1,
            //             scrollShow: false,
            //             scrollAlign: "left",
            //             scrollColor: "#A6A6A6",
            //             scrollBackgroundColor: "#EFEBEF",
            //             format: ""
            //         },
            //         yAxis: {
            //             gridType: "dash",
            //             dashLength: 2,
            //             disabled: false,
            //             disableGrid: false,
            //             splitNumber: 5,
            //             gridColor: "#CCCCCC",
            //             padding: 10,
            //             showTitle: false,
            //             data: []
            //         },
            //         extra: {
            //             area: {
            //                 type: "curve",
            //                 opacity: 0.4,
            //                 addLine: true,
            //                 width: 2,
            //                 gradient: true
            //             },
            //             tooltip: {
            //                 showBox: true,
            //                 showArrow: true,
            //                 showCategory: false,
            //                 borderWidth: 0,
            //                 borderRadius: 0,
            //                 borderColor: "#000000",
            //                 borderOpacity: 0.7,
            //                 bgColor: "#000000",
            //                 bgOpacity: 0.7,
            //                 gridType: "solid",
            //                 dashLength: 4,
            //                 gridColor: "#CCCCCC",
            //                 fontColor: "#FFFFFF",
            //                 splitLine: false,
            //                 horizentalLine: false,
            //                 xAxisLabel: false,
            //                 yAxisLabel: false,
            //                 labelBgColor: "#FFFFFF",
            //                 labelBgOpacity: 0.7,
            //                 labelFontColor: "#666666"
            //             }
            //         }
            //     });
            // }
        }
    };
</script>

<style lang="scss">

	@function vw($width) {
		@return ($width * 1vw / 19.2);
	}

	@function vh($height) {
		@return ($height * 1vh / 10.8);
	}

	.lines {
		width: vw(377);
		height: vh(150);

		.canvas {
			width: 100%;
			height: 100%;
		}
	}
</style>
