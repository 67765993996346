import Vue from 'vue'
import VueRouter from 'vue-router'
import home from "../views/home.vue";
import area from "../views/area.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: home
    }, {
        path: '/home',
        name: 'home',
        component: home,
        title:'襄垣县殡葬服务智慧管理系统'
    }, {
        path: '/area',
        name: 'area',
        component: area,
        title:'襄垣县殡葬服务智慧管理系统'
    }
]

const router = new VueRouter({
    mode: "hash",
    routes
})

export default router;